<template>
  <b-card title="マスターユーザー登録">
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group label="ログインID" label-for="h-email" label-cols-md="2">
              <template #label>
                <div class="required-label">
                  <span class="label">ログインID</span>
                  <span class="required">必須</span>
                </div>
              </template>
              <validation-provider #default="{ errors }" vid="login_id" rules="required|email|max:60" name="ログインID">
                <b-form-input v-model="form.login_id" :state="errors.length > 0 ? false : null" placeholder=""
                  @blur.prevent="getExistingFieldsByLoginId()" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="利用者名" label-for="h-email" label-cols-md="2">
              <validation-provider #default="{ errors }" vid="user_name" rules="max:32" name="利用者名">
                <b-form-input v-model="form.user_name" :state="errors.length > 0 ? false : null"
                  :readonly="existingFieldsFromApi" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="利用者名（カナ）" label-for="h-email" label-cols-md="2">
              <validation-provider #default="{ errors }" vid="user_name_kana" rules="max:32" name="利用者名（カナ）">
                <b-form-input v-model="form.user_name_kana" :state="errors.length > 0 ? false : null"
                  :readonly="existingFieldsFromApi" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="電話番号" label-for="h-email" label-cols-md="2">
              <validation-provider #default="{ errors }" vid="user_tel" rules="max:11" name="電話番号">
                <b-form-input v-model="form.user_tel" :state="errors.length > 0 ? false : null"
                  :readonly="existingFieldsFromApi" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="携帯番号" label-for="h-email" label-cols-md="2">
              <template #label>
                <div class="required-label">
                  <span class="label">携帯番号</span>
                </div>
              </template>
              <validation-provider #default="{ errors }" vid="user_mobile" rules="max:11" name="携帯番号">
                <b-form-input v-model="form.user_mobile" :state="errors.length > 0 ? false : null"
                  :readonly="existingFieldsFromApi" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="登録者" label-for="h-email" label-cols-md="2">
              <b-form-input v-model="form.reg_id" vid="user_mobile" readonly />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="登録日時" label-for="h-email" label-cols-md="2">
              <b-form-input v-model="form.reg_datetime" readonly />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="変更者" label-for="h-email" label-cols-md="2">
              <b-form-input v-model="form.upd_id" readonly />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="変更日時" label-for="h-email" label-cols-md="2">
              <b-form-input v-model="form.upd_datetime" readonly />
            </b-form-group>
          </b-col>

          <!-- !! Change here -------------------------------------------------------------------------------------------->
          <b-col md="12">
            <validation-provider #default="{ errors }" vid="totocd" rules="required" name="得意先">
              <b-form-group label="得意先" label-for="h-email" label-cols-md="2" :state="errors.length > 0 ? false : null">
                <template #label>
                  <div class="required-label">
                    <span class="label">得意先</span>
                    <span class="required">必須</span>
                  </div>
                </template>
                <v-select v-model="selected" label="item_data" :options="paginated" @input="changeEvent"
                  :filterable="false" @open="onOpen" @close="onClose" @search="(query) => (search = query)">
                  <template #no-options>
                    入力された営業コードは、存在しません。
                  </template>
                  <template #list-footer>
                    <li v-show="hasNextPage" ref="load" class="loader">
                      他のオプションを読み込んでいます...
                    </li>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <b-form-group label="事務所電話番号" label-for="h-email" label-cols-md="2">
              <b-form-input v-model="form.totel" readonly />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="工場電話番号" label-for="h-email" label-cols-md="2">
              <b-form-input v-model="form.toktel" readonly />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="自宅電話番号" label-for="h-email" label-cols-md="2">
              <b-form-input v-model="form.tomtel" readonly />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="役職" label-for="h-email" label-cols-md="2">
              <validation-provider #default="{ errors }" vid="user_yakushoku" name="役職" rules="">
                <b-form-input v-model="form.user_yakushoku" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="アカウント種別" label-for="h-email" label-cols-md="2">
              <b-form-input v-model="form.user_role_name" readonly />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="営業所" label-for="h-email" label-cols-md="2">
              <b-form-input v-model="form.jename" readonly />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="営業担当" label-for="h-email" label-cols-md="2">
              <b-form-input v-model="form.einame" readonly />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="価格閲覧許可" label-for="h-email" label-cols-md="2">
              <b-form-input v-model="strKakakuFlag" readonly />
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col cols="12" class="btn-center">
            <b-button variant="primary" type="button" @click.prevent="validationForm">
              登録
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <Dialog :dialog.sync="dialog" :title="'ユーザー情報の登録'" :btnOk="'登録'" :btnCancel="'キャンセル'" @submit="submitForm">
      <template slot="content">
        ユーザー情報の登録を行ってよろしいですか？
        <div>
          <span>得意先名：{{ tonam1 }}{{ tonam2 }}</span>
        </div>
      </template>
    </Dialog>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCardText,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormSelect,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  max,
} from "@validations";
import vSelect from "vue-select";
import { apiService } from "@/services/api.service";
import Dialog from "@core/components/dialog/Dialog";
import {STATUS} from "@/constant/constant";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    vSelect,
    Dialog
  },
  data() {
    return {
      form: {
        login_id: "",
        user_name: "",
        user_name_kana: "",
        user_tel: "",
        user_mobile: "",
        reg_id: "",
        reg_datetime: "",
        upd_id: "",
        upd_datetime: "",
        totocd: "",
        user_yakushoku: "",
        user_role_name: "マスター",
        user_role: "1",
        user_status: "1",
        jeegcd: "",
        tojicd: "",
        totel: "",
        toktel: "",
        tomtel: "",
        jename: "",
        einame: "",
      },
      strKakakuFlag: "許可する",
      existingFieldsFromApi: false,
      tonam1: "",
      tonam2: "",
      dir: "ltr",
      selected: "",
      listOfCustomerMaster: [],
      dialog: false,
      //Infinity scroll
      observer: null,
      limit: 50,
      search: '',
    };
  },
  mounted() {
    this.getFieldsData();
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  computed: {
    filtered() {
      return this.listOfCustomerMaster.filter((item) => item.item_data.includes(this.search))
    },
    paginated() {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length
    },
  },
  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 50
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    async getExistingFieldsByLoginId() {
      let loginId = this.form.login_id;
      if (loginId && this.validateEmailFormat(loginId)) {
        this.startLoading();
        this.existingFieldsFromApi = false; // Set fields to be enabled
        await apiService
          .post(`user/get-existing-fields`, { login_id: loginId } )
          .then((response) => {
            this.endLoading();
            let responseData = response.data.data;
            if (responseData.usersExistWithLoginId == true) {
              this.form.user_name = responseData.user_name;
              this.form.user_name_kana = responseData.user_name_kana;
              this.form.user_tel = responseData.user_tel;
              this.form.user_mobile = responseData.user_mobile;
              this.existingFieldsFromApi = true;
            } else {
              this.resetExistingFieldsByLoginId();
            }
          })
          .catch((error) => {
            this.endLoading();
            if (error.status === STATUS.BAD_REQUEST) {
              this.$refs.simpleRules.setErrors(error.data.data);
              window.scrollTo(0, 0);
            } else {
              this.notifyError(error.data.message);
            }
          });
      } else {
        this.resetExistingFieldsByLoginId();
      }
    },
    resetExistingFieldsByLoginId() {
      this.existingFieldsFromApi = false;
      this.form.user_name = "";
      this.form.user_name_kana = "";
      this.form.user_tel = "";
      this.form.user_mobile = "";
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.dialog = true;
        }
      });
    },
    async submitForm() {
      this.startLoading();
      await apiService
        .post(`user/create`, this.form)
        .then((response) => {
          this.endLoading();
          this.notifySuccess(response.data.message);
          setTimeout(() => {
            this.$router.push({ name: "user-list" });
          }, 1000);
        })
        .catch((error) => {
          this.endLoading();
          if (error.status === HTTP_STATUS.UNPROCESSABLE_ENTITY) {
            this.$refs.simpleRules.setErrors(error.data.data);
            window.scrollTo(0, 0);
          } else {
            this.notifyError(error.data.message);
          }
        });
    },
    changeEvent() {
      if (this.selected) {
        this.form.totocd = this.selected.totocd;
        this.form.tojicd = this.selected.tojicd;
        this.form.jeegcd = this.selected.toegcd;
        this.form.jeegcd = this.selected.toegcd;
        this.form.totel = this.selected.totel;
        this.form.toktel = this.selected.toktel;
        this.form.tomtel = this.selected.tomtel;
        this.form.jename = this.selected.jename;
        this.form.einame = this.selected.einame;
        this.tonam1 = this.selected.tonam1;
        this.tonam2 = this.selected.tonam2;
      }
    },
    async getFieldsData() {
      this.endLoading();
      await apiService
        .get('user/get-fields-data')
        .then((response) => {
          this.listOfCustomerMaster = response.data.data.listOfCustomerMaster;
          this.listOfCustomerMaster = this.listOfCustomerMaster.map(x => {
            x.item_data = `${this.formatCustomerMaster(x.tojicd, x.toegcd, x.totocd)} ${x.tonam1}${x.tonam2}`
            return x;
          });
          this.endLoading();
        })
        .catch((error) => {
          this.endLoading();
          this.notifyError(error.data.message);
        });
    },
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.required-label {
  display: flex;
  justify-content: space-between;

  .required {
    color: red
  }
}
</style>
<style scoped>
.btn-center {
  text-align: center;
}

.col-form-label {
  font-weight: bold;
}

.loader {
  text-align: center;
  color: #bbbbbb;
}
</style>
